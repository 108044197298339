
import MIcon from "@/components/MIcon.vue";
import MTableCell from "@/components/MTableCell.vue";
import { TableDataType, TableDefinition, formatUnixtime } from "@/utils/table";
import { Options, Vue } from "vue-class-component";

function formatValue(value: string | number | boolean, type: TableDataType) {
  if (type === "unixtime") {
    return formatUnixtime(value as number);
  } else {
    return value?.toString();
  }
}

function generateCellData(
  data: { [key: string]: string | number | boolean },
  definition: TableDefinition
): CellData {
  let text = "";
  if (definition.key === "recessTime") {
    if (data[definition.key] && data[definition.key] > 0) {
      text = "休止";
    } else {
      text = "有効";
    }
  } else if (definition.subType === "original") {
    text = data[definition.key] ? data[definition.key].toString() : "";
  } else if (definition.key === "schoolAILinkageStatus") {
    text = data[definition.key] ? data[definition.key].toString() : "";
    // if (data[definition.key] === true) {
    //   text = "連携済み";
    // } else {
    //   text = "未連携";
    // }
  } else if (definition.key === "qandaLinkageStatus") {
    text = data[definition.key] ? data[definition.key].toString() : "";
    // if (data[definition.key] === true) {
    //   text = "連携済み";
    // } else {
    //   text = "未連携";
    // }
  } else {
    text = formatValue(data[definition.key].toString(), definition.type);
  }

  return {
    // text:
    //   definition.key === "recessTime"
    //     ? data[definition.key] && data[definition.key] > 0
    //       ? "休止"
    //       : "有効"
    //     : definition.subType === "original"
    //     ? data[definition.key]
    //       ? data[definition.key].toString()
    //       : ""
    //     : formatValue(data[definition.key].toString(), definition.type),
    text: text,
    prefix: definition.prefix
      ? formatValue(data[`pre-${definition.key}`], definition.prefixType)
      : "",
    suffix: definition.suffix
      ? formatValue(data[`suf-${definition.key}`], definition.suffixType)
      : "",
    size: definition.size,
    maxSize: definition.maxSize,
    showOnMobile: definition.showOnMobile,
    extraClass: definition.extraClass,
    property: definition.key,
    hover: definition.hover
  };
}

function generateRowData(
  data: { [key: string]: string | number | boolean },
  definitions: TableDefinition[],
  selectedIds: string[],
  coloredRowList: {
    key: string;
    data: string | number | boolean;
    color: string;
  }[]
): RowData {
  let id: string;
  if (Object.keys(data).includes("id")) {
    id = data.id as string;
  } else {
    id = "";
  }

  let schoolDocId: string;
  if (Object.keys(data).includes("schoolDocId")) {
    schoolDocId = data.schoolDocId as string;
  } else {
    schoolDocId = "";
  }

  let classroomDocId: string;
  if (Object.keys(data).includes("classroomDocId")) {
    classroomDocId = data.classroomDocId as string;
  } else {
    classroomDocId = "";
  }

  let link: string;
  if (Object.keys(data).includes("link")) {
    link = data.link as string;
  } else {
    link = "";
  }

  let order: number;
  if (Object.keys(data).includes("order")) {
    order = data.order as number;
  } else {
    order = 0;
  }

  let relatedLink: string;
  if (Object.keys(data).includes("relatedLink")) {
    relatedLink = data.relatedLink as string;
  } else {
    relatedLink = "";
  }

  if (
    Object.keys(data).includes("properties") &&
    Array.isArray(data.properties)
  ) {
    data.properties.map(item => {
      const { id, value } = item;
      data[id] = value;
    });
  }

  const res = {
    id,
    schoolDocId,
    classroomDocId,
    schoolAIUserId: data.schoolAIUserId as string,
    link,
    order,
    relatedLink,
    select: selectedIds.includes(data.id as string),
    datas: definitions.map(def => generateCellData(data, def))
  };

  const color = coloredRowList.find(e => e.data === data[e.key])?.color;
  return color ? { ...res, color } : res;
}

type CellData = {
  text: string;
  prefix: string;
  suffix: string;
  size: number;
  maxSize?: string;
  showOnMobile: boolean;
  extraClass?: string;
  property?: string;
  hover?: boolean;
};

type RowData = {
  id: string;
  schoolDocId: string;
  classroomDocId: string;
  link: string;
  order: number;
  select: boolean;
  datas: CellData[];
  color?: string;
  schoolAIUserId?: string;
};

@Options({
  components: {
    MIcon,
    MTableCell
  },
  emits: ["change", "select", "copy"],
  props: {
    datas: Array,
    definitions: Array,
    selectedIds: Array,
    order: Boolean,
    selectable: Boolean,
    editable: Boolean,
    useActions: Boolean,
    useSubmission: Boolean,
    coloredRowList: Array
  },
  watch: {
    datas() {
      this.onUpdateDatas();
    },
    definitions() {
      this.onUpdateDatas();
    }
  }
})
export default class MTableContents extends Vue {
  datas: { [key: string]: string | number | boolean }[] = [];
  definitions: TableDefinition[] = [];
  selectedIds: string[] = [];
  order = false;
  selectable = false;
  editable = false;
  useActions = false;
  useSubmission = false;
  coloredRowList: {
    key: string;
    data: string | number | boolean;
    color: string;
  }[] = [];

  rowDatas: RowData[] = [];

  async onUpdateDatas() {
    const baseDatas = [...this.datas];
    this.rowDatas = baseDatas.map(data =>
      generateRowData(
        data,
        this.definitions,
        this.selectedIds,
        this.coloredRowList
      )
    );
  }

  created() {
    this.onUpdateDatas();
  }

  change(id: string) {
    this.$emit("change", id);
  }

  select(id: string) {
    this.$emit("select", id);
  }
  copy(id: string) {
    this.$emit("copy", id);
  }
}
